import React from "react"
import {ApiDomain, currentTitle} from "./consts";
import {Bikes} from "./bikeList";
import {Helmet} from "react-helmet";

export default class Brand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      collection: {}
    }
  }

  componentDidMount() {
    document.title = currentTitle(this.props.match.params.name);
    fetch(ApiDomain + "/api/collections/" + this.props.match.params.name)
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({
                isLoaded: true,
                collection: result
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  render() {
    const {error, isLoaded, collection} = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      );
    } else {
      return (
          <div className="collection col-12 col-sm-12 col-md-9 col-lg-8">
            <Helmet>
              <meta name="description" content={`${collection.name} 品牌、${collection.name} 图片、${collection.name} 资料`}/>
            </Helmet>
            <h1 className="display-4">{collection.name}</h1>
            <div className="text-center" style={{backgroundColor: 'white', padding: '15px 0'}}>
              <img src={collection.iconUrl} className="col-3"/>
            </div>
            <div className="bike-list">
              <Bikes items={collection.entities}/>
            </div>
          </div>
      );
    }
  }
}