import React, {Component} from 'react';
import {Nav, Navbar, NavItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import {CompareCandidates} from './context';

export default class Header extends Component {
  constructor(props) {
    super(props);

    // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
    if (typeof window !== 'undefined') {
      let prevScrollPos = window.pageYOffset;
      window.addEventListener("scroll", () => {
        const maxScroll = document.body.clientHeight - window.innerHeight;
        const currentScrollPos = window.pageYOffset;
        let top = currentScrollPos;
        if (
            (maxScroll > 0 && prevScrollPos > currentScrollPos && prevScrollPos <= maxScroll)
            || (maxScroll <= 0 && prevScrollPos > currentScrollPos)
            || (prevScrollPos <= 0 && currentScrollPos <= 0)
        ) {
          if (top > 56) {
            top = 0
          }
          document.getElementById("navbar").style.top = "-" + top + "px";
        } else {
          if (top > 56) {
            top = 56
          }
          document.getElementById("navbar").style.top = "-" + top + "px";
        }
        prevScrollPos = currentScrollPos;
      });
    }
  }

  render() {
    return (
        <div className="header">
          <Navbar id="navbar" color="orange" light fixed="top" expand>
            <div className="navbar-header">
              <Link to="/" className="navbar-brand">61摩托</Link>
            </div>
            <Nav className="d-flex flex-row" navbar>
              <NavItem>
                <Link to="/bikes" className="nav-link">车列表</Link>
              </NavItem>
              <CompareCandidates.Consumer>
                {({candidates, _0, _1}) => {
                  return (<CompareNavItem candidates={candidates}/>)
                }}
              </CompareCandidates.Consumer>

              <NavItem>
                <Link to="/download" className="nav-link">下载APP</Link>
              </NavItem>
            </Nav>
          </Navbar>
        </div>
    );
  }
}

class CompareNavItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let candidates = this.props.candidates;
    if (candidates.length > 0) {
      return (
          <NavItem>
            <Link to={`/compare?names=${candidates.map(encodeURI).join()}`}
                  className="nav-link">比较({candidates.length})</Link>
          </NavItem>
      );
    } else {
      return (
          '' // empty
      );
    }
  }
}