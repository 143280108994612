import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Helmet} from "react-helmet";
import {CompareCandidates} from './context'
import Header from './header';
import BikeList from './bikeList';
import Bike from './bike';
import Footer from './footer';
import Compare from './compare';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import Search from "./search";
import Download from "./download";
import Page404 from "./page404";
import BrandList from "./brandList";
import Brand from "./brand";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      candidates: [],
      addItems: this.addToCompare.bind(this),
      removeItems: this.removeFromCompare.bind(this)
    }
  }

  addToCompare(names) {
    const newNames = names.filter((e) => !this.state.candidates.includes(e));
    if (newNames.length > 0) {
      let candidates = this.state.candidates;
      candidates.push(...newNames);
      this.setState({candidates: candidates});
    }
  }

  removeFromCompare(names) {
    let candidates = this.state.candidates.filter((item) => !names.includes(item));
    this.setState({ candidates: candidates });
  }

  render() {
    return (
      <Router>
        <CompareCandidates.Provider value={this.state}>
          <Helmet>
            <meta content="摩托车参数、摩托车图片、摩托车资料" name="description"/>
            <meta name="robots" content="index, follow"/>
          </Helmet>
          <Header />
          <div className="container">
            <Switch>
              <Route exact path={`/`} component={BrandList}/>
              <Route exact path={`/bikes`} component={BikeList} />
              <Route exact path={`/search`} component={Search} />
              <Route path={`/bikes/:name`} component={Bike} />
              <Route exact path={`/compare`} component={Compare} />
              <Route exact path={`/download`} component={Download} />
              <Route path={`/brands/:name`} component={Brand}/>
              <Route component={Page404}/>
            </Switch>
          </div>
          <Footer />
        </CompareCandidates.Provider>
      </Router >
    );
  }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);