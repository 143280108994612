import React, {Component} from 'react';

export default class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer>
        <div>
          <img src="/logo.png" />
        </div>
        <div>
          <ul>
            {/*<li>© 2019 哈希空间</li>*/}
            {/*<li><a href="http://www.miibeian.gov.cn/">京ICP备18058396号</a></li>*/}
          </ul>
        </div>
      </footer>
    );
  }
}