import React from 'react';
import {ApiDomain, currentTitle} from './consts';
import {CompareCandidates} from './context';
import {Helmet} from "react-helmet";

export default class Bike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      bike: {}
    }
  }

  componentDidMount() {
    document.title = currentTitle(this.props.match.params.name);
    fetch(ApiDomain + "/api/entities/" + this.props.match.params.name)
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({
                isLoaded: true,
                bike: result
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  renderAttrValue(attribute) {
    if (attribute.name !== "Image") {
      return (
          <div key={attribute.name} className="col-12 row specs-item">
            <dt className="col">{attribute.name}</dt>
            <dd className="col">{attribute.value}</dd>
          </div>
      );
    }
  }

  renderBikeImage(bike) {
    let bikeImageAttr = bike.attributes.find((attr) => attr.name === "Image");
    if (bikeImageAttr !== 'undefined') {
      return (
          <img src={bikeImageAttr.value} alt={bike.name} style={{width: '100%'}}/>
      );
    }
  }

  render() {
    const {error, isLoaded, bike} = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      );
    } else {
      return (
          <div className="bike">
            <Helmet>
              <meta name="description" content={`${bike.name} 参数、${bike.name} 图片、${bike.name} 资料`}/>
            </Helmet>
            <div>
              <h1 className="display-4">{bike.name}</h1>
              <div className="row">
                <div style={{marginLeft: 'auto', marginRight: '0'}}><AddToCompare name={bike.name}/></div>
              </div>
              <div className="specs row">
                <span className="col-sm-12 col-md-3 col-lg-2" style={{textAlign: 'center'}}>
                  <div>{this.renderBikeImage(bike)}</div>
                  <h4 className="display-5">参数列表</h4>
                </span>
                <dl className="row col" style={{backgroundColor: 'white', padding: '20px'}}>
                  {bike.attributes.map(this.renderAttrValue)}
                </dl>
              </div>
            </div>
          </div>
      );
    }
  }
}

export class AddToCompare extends React.Component {
  constructor(props) {
    super(props);

    this.checkBox = React.createRef();
    this.addToCompare = this.addToCompare.bind(this);
  }

  addToCompare(addFunc, removeFunc) {
    let checked = this.checkBox.current.checked;
    let name = this.props.name;
    if (checked) {
      addFunc([name]);
    } else {
      removeFunc([name]);
    }
  }

  checked(candidates) {
    if (typeof candidates.find((candidate) => candidate === this.props.name) !== 'undefined') {
      return 'checked';
    }
    return '';
  }

  render() {
    return (
        <CompareCandidates.Consumer>
          {({candidates, addItems, removeItems}) => (
              <div className="custom-control form-control-lg custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={this.props.name} ref={this.checkBox}
                       onChange={() => this.addToCompare(addItems, removeItems)} checked={this.checked(candidates)}/>
                <label className="custom-control-label" htmlFor={this.props.name}>添加到比较</label>
              </div>
          )}
        </CompareCandidates.Consumer>
    );
  }
}