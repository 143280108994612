import React from 'react';
import {Link} from 'react-router-dom';
import {ApiDomain, currentTitle} from './consts';
import {SearchBox} from "./search";
import {AddToCompare} from "./bike";

export default class BikeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      next: null,
      end: false
    };

    this.loadMore = this.loadMore.bind(this);
    this.loader = React.createRef();
  }

  componentDidMount() {
    document.title = currentTitle("所有车列表");
    this.fetch();
    window.addEventListener("scroll", this.onscroll.bind(this));
  }

  fetch(next) {
    let params = "";
    if (typeof (next) !== 'undefined') {
      params = "?" + next;
    }

    fetch(ApiDomain + "/api/entities" + params)
        .then(res => res.json())
        .then(
            (result) => {
              const ids = this.state.items.map(it => it.id);

              this.setState({
                isLoaded: true,
                items: this.state.items.concat(result.list.filter(it => !ids.includes(it.id))),
                next: result.next,
                end: result.list.length === 0
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  loadMore() {
    this.fetch(this.state.next);
  }

  onscroll() {
    let loader = this.loader.current;
    if (loader != null) {
      let rect = loader.getBoundingClientRect();
      let visible = rect.top - window.innerHeight < 0;

      if (visible) {
        this.loadMore();
      }
    }
  }

  getLoaderContent() {
    if (this.state.end) {
      return (
          <div/>
      );
    } else {
      return (
          <div><p onClick={this.loadMore} ref={this.loader}>加载更多...</p></div>
      );
    }
  }

  render() {
    const {error, isLoaded, items} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      );
    } else {
      return (
          <div className="bike-list col-12 col-sm-12 col-md-9 col-lg-8">
            <h1 className="display-4">所有车列表</h1>
            <SearchBox/>
            <Bikes items={items}/>
            {this.getLoaderContent()}
          </div>
      );
    }
  }
}

export class Bikes extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="list">
          {this.props.items.map(item => (
              <div className="card" key={item.id}>
                <div className="row">
                  <Link to={`/bikes/${item.name}`} className="card-body col-8 row">
                    <div className="col-5 ml-auto" style={{transform: 'translate(15px, 0)'}}>
                      <img src={item.attributes[0].value} className="card-img"/>
                    </div>
                    <div className="col">
                      <h5 className="card-title text-muted">{item.name}</h5>
                    </div>
                  </Link>
                  <div className="col add-to-compare">
                    <AddToCompare name={item.name}/>
                  </div>
                </div>
              </div>
          ))}
        </div>
    );
  }
}