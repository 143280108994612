import React from 'react';
import {ApiDomain, currentTitle} from './consts';
import {CompareCandidates} from "./context";
import CompareTable from "./compareTable";

export default class Compare extends React.Component {
  static contextType = CompareCandidates;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: this.getNames().map(e => ({
        name: decodeURI(e),
        attributes: []
      }))
    };
  }

  componentDidMount() {
    document.title = currentTitle('车比较');
    this.context.addItems(this.state.items.map((e) => e.name));

    this.state.items.forEach(e => {
      fetch(ApiDomain + "/api/entities/" + e.name)
          .then(res => res.json())
          .then(
              (result) => {
                let items = this.state.items;
                items[items.findIndex(i => i.name === e.name)].attributes = result.attributes;
                this.setState({
                  isLoaded: true,
                  items: items
                });
              },
              (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
          )
    });
  }

  getNames() {
    return this.props.location.search
        .substr("?".length).split("&").map((it) => it.split("="))
        .find((it) => it[0] === "names")[1].split(",").filter((e) => e.length > 0)
        .map((it) => decodeURI(it));
  }

  removeItems(items) {
    const newNames = this.getNames().filter((it) => !items.map((it) => it.name).includes(it));

    if (newNames.length > 0) {
      this.props.history.push({search: `names=${newNames.map((it) => encodeURI(it)).join()}`});
    } else {
      this.props.history.push();
    }

    this.context.removeItems(items.map((item) => item.name));
  }

  render() {
    const {items} = this.state;
    return (
        <div className="compare">
          <h1 className="display-4">比较车参数</h1>
          <CompareTable
              items={items}
              removeItemsCallback={(e) => this.removeItems(e)}
              clickTitleCallback={(title) => this.props.history.push(`/bikes/${title}`)}
              clickAddMoreTitleCallback={() => this.props.history.push("/bikes")}
              highLightColor='#ff9f1a47'
              backgroundColor='#F6F6F6'
          />
        </div>
    );
  }
}