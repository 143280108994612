import React from "react"
import Octicon, {ChevronLeft, ChevronRight, Plus, X} from "@primer/octicons-react";

export default class CompareTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      firstItemId: 1,
      highlightDifference: false,
      floatHead: false,
      columnCount: this.getItemCount()
    };

    this.moveRight = this.moveRight.bind(this);
    this.moveLeft = this.moveLeft.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.removeItems = this.removeItems.bind(this);
    this.switchHighlightState = this.switchHighlightState.bind(this);
    this.tableHeadElement = React.createRef();
    this.tableHeadElementTop = 0;

    window.addEventListener("scroll", () => {
      this.setState({floatHead: window.pageYOffset > this.tableHeadElementTop});
    });

    window.addEventListener('resize', () => {
      this.setState({columnCount: this.getItemCount()});
      this.updateFirstItemId();
    });
  }

  getItemCount() {
    const width = window.innerWidth;
    let count = 6;

    if (width < 1200) {
      count = 4;
    }

    if (width < 900) {
      count = 3
    }

    return count;
  }

  updateFirstItemId() {
    let {firstItemId} = this.state;
    while (firstItemId > this.itemLength() - this.state.columnCount + 1) {
      firstItemId = firstItemId - 1;
    }

    this.setState({firstItemId: firstItemId});
  }

  componentDidMount() {
    const rect = this.tableHeadElement.current.getBoundingClientRect();
    this.tableHeadElementTop = rect.top;
  }

  moveLeft() {
    if (this.leftMovable()) {
      this.setState({firstItemId: this.state.firstItemId - 1});
    }
  }

  moveRight() {
    if (this.rightMovable()) {
      this.setState({firstItemId: this.state.firstItemId + 1});
    }
  }

  leftMovable() {
    return this.state.firstItemId > 1
  }

  rightMovable() {
    return this.state.firstItemId < this.state.items.length - this.state.columnCount + 1
  }

  moveRighterStyle() {
    const style = {position: 'absolute', top: '50%', right: 0, cursor: '', color: 'gray'};
    if (this.rightMovable()) {
      style.cursor = 'pointer';
      style.color = '#007bff';
    }
    return style;
  }

  moveLefterStyle() {
    const style = {position: 'absolute', top: '50%', left: 0, cursor: '', color: 'gray'};
    if (this.leftMovable()) {
      style.cursor = 'pointer';
      style.color = '#007bff';
    }
    return style;
  }

  removeItem(item) {
    this.removeItems([item]);
  }

  removeItems(items) {
    const {removeItemsCallback} = this.props;
    const newItems = this.state.items.filter((it) => !items.includes(it));
    this.setState({items: newItems}, () => {
      this.updateFirstItemId();
    });

    if (removeItemsCallback !== 'undefined') {
      removeItemsCallback(items);
    }
  }

  switchHighlightState() {
    this.setState({highlightDifference: !this.state.highlightDifference})
  }

  tableBodyStyle() {
    let style = {marginTop: 0};
    if (this.state.floatHead) {
      const rect = this.tableHeadElement.current.getBoundingClientRect();
      style.marginTop = rect.height + 60 + 'px';
    }
    return style;
  }

  tableHeadImageStyle() {
    let style = {display: 'block', width: '100%'};
    if (this.state.floatHead) {
      style.display = 'none';
    }
    return style;
  }

  itemLength() {
    return this.state.items.length < this.state.columnCount ? this.state.columnCount : this.state.items.length;
  }

  tableWidth(ratio) {
    return ratio * (20 + 80 * this.itemLength() / this.state.columnCount) + '%';
  }

  margin() {
    const {firstItemId, columnCount} = this.state;
    return -(25 + (firstItemId - 1) * 100 / columnCount) + '%';
  }

  tableHead(items) {
    const {columnCount} = this.state;
    const itemWidth = 400 / (columnCount + 4 * this.itemLength());
    const thStyle = {position: 'relative', padding: '20px 10px 10px 10px', textAlign: 'center', width: itemWidth + '%'};
    return (
        <table style={{width: '100%', tableLayout: 'fixed'}}>
          <thead>
          <tr>
            <th style={{
              position: 'relative',
              padding: '20px 10px 10px 10px',
              textAlign: 'center',
              width: 100 - this.itemLength() * itemWidth + '%'
            }}>{items.length} 个对象
            </th>
            {items.map(item => {
              const attr = item.attributes.find(attr => attr.name === 'Image');
              let url = '';
              if (typeof attr !== 'undefined') {
                url = attr.value;
              }
              return (
                  <th style={thStyle}>
                    <span style={{position: 'absolute', top: 0, right: 5, cursor: 'pointer'}}
                          onClick={() => {
                            this.removeItem(item);
                          }}><Octicon icon={X}/></span>
                    <div>
                      <img style={this.tableHeadImageStyle()} src={url}/>
                      <p><a onClick={() => this.props.clickTitleCallback(item.name)}
                            style={{cursor: 'pointer'}}>{item.name}</a></p>
                    </div>
                  </th>
              );
            })}
            {(items.length < columnCount) ? Array.from(Array(columnCount - items.length).keys()).map((i) => {
              return (
                  <th key={"empty" + i} scope="col" style={thStyle}>
                    <a onClick={this.props.clickAddMoreTitleCallback} style={{cursor: 'pointer'}}>
                      <Octicon icon={Plus}/>
                      <p>添加</p>
                    </a>
                  </th>
              );
            }) : ''}
          </tr>
          </thead>
        </table>
    );
  }

  tableBody(attributeTitles, items) {
    const {highlightDifference, columnCount} = this.state;
    const {highLightColor} = this.props;
    return (
        <table style={{width: '100%', tableLayout: 'fixed'}}>
          <tbody>
          {attributeTitles.filter(title => title !== 'Image').map(title => {
            const currentRowItems = items.map(item => {
              const attr = item.attributes.find(attr => attr.name === title);
              let value = '';
              if (typeof attr !== 'undefined') {
                value = attr.value;
              }
              return value;
            });

            const isDifferent = currentRowItems.length <= 1 ? false : (currentRowItems.filter((e) => currentRowItems[0] !== e).length > 0);
            const backgroundColor = (isDifferent && highlightDifference) ? highLightColor : 'white';
            const itemWidth = 400 / (columnCount + 4 * this.itemLength());
            const tdStyle = {backgroundColor: backgroundColor, width: itemWidth + '%'};
            return (
                <tr>
                  <td style={{width: 100 - this.itemLength() * itemWidth + '%', textAlign: 'center'}}>{title}</td>
                  {currentRowItems.map(value => {
                    return (
                        <td style={tdStyle}>{value}</td>
                    );
                  })}
                  {(items.length < columnCount) ? Array.from(Array(columnCount - items.length).keys()).map((i) => {
                    return (
                        <td style={tdStyle}/>
                    );
                  }) : ''}
                </tr>
            );
          })}
          </tbody>
        </table>
    );
  }

  tableHeadStyle() {
    const {backgroundColor} = this.props;
    let style = {position: 'relative', backgroundColor: backgroundColor, width: '100%', paddingBottom: '10px'};
    if (this.state.floatHead) {
      let compareTable = document.getElementById("compare-table");
      style.width = compareTable.getBoundingClientRect().width + 'px';
      style.position = 'fixed';
      style.top = 0;
    }
    return style;
  }

  render() {
    const {items, highlightDifference} = this.state;
    const attributeTitles = [...new Set(items.flatMap((item) => item.attributes.map((attr) => attr.name)))];
    return (
        <div className="compare-body" id="compare-table">
          <div style={this.tableHeadStyle()} ref={this.tableHeadElement}>
            <div style={{textAlign: 'right', margin: '15px 0'}}>
              <span style={{margin: '20px'}} className="custom-checkbox">
                <input type="checkbox" id="highlight-difference" className="custom-control-input"
                       checked={highlightDifference ? 'checked' : ''} onClick={this.switchHighlightState}/>
                <label htmlFor="highlight-difference" className="custom-control-label">高亮不同</label>
              </span>
              <span onClick={() => this.removeItems(items)}><Octicon icon={X}/>清空所有</span>
            </div>
            <div style={{width: '100%'}}>
              <div style={{width: '20%', overflow: 'hidden', float: 'left'}}>
                <div style={{width: this.tableWidth(5)}}>{this.tableHead(items)}</div>
              </div>
              <div style={{width: '80%', overflow: 'hidden'}}>
                <div style={{
                  marginLeft: this.margin(),
                  overflow: 'hidden',
                  width: this.tableWidth(1.25)
                }}>{this.tableHead(items)}</div>
              </div>
            </div>
            <div onClick={() => this.moveLeft()}
                 style={this.moveLefterStyle()}>
              <Octicon icon={ChevronLeft}/>
            </div>
            <div onClick={() => this.moveRight()}
                 style={this.moveRighterStyle()}>
              <Octicon icon={ChevronRight}/>
            </div>
          </div>
          <div style={this.tableBodyStyle()}>
            <div style={{width: '20%', overflow: 'hidden', float: 'left'}}>
              <div style={{width: this.tableWidth(5)}}>{this.tableBody(attributeTitles, items)}</div>
            </div>
            <div style={{width: '80%', overflow: 'hidden'}}>
              <div style={{
                marginLeft: this.margin(),
                overflow: 'hidden',
                width: this.tableWidth(1.25)
              }}>{this.tableBody(attributeTitles, items)}</div>
            </div>
          </div>
        </div>
    );
  }
}