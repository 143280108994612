import React from "react";
import {ApiDomain, currentTitle} from "./consts";
import {Bikes} from "./bikeList";
import {Redirect} from "react-router-dom";

export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            keyword: this.getKey(props.location.search)
        };

      this.fetch = this.fetch.bind(this);
        this.updateKeyword = this.updateKeyword.bind(this)
    }

    componentDidMount() {
        document.title = currentTitle('搜索');
        this.fetch(this.state.keyword);
    }

    getKey(search) {
        return decodeURI(search.substring("?key=".length));
    }

    fetch(keyword) {
        let params = "";
        if (typeof (keyword) !== 'undefined' && keyword.length > 0) {
            params = "?key=" + keyword;
        }

        fetch(ApiDomain + "/api/entities/search" + params)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        keyword: keyword,
                        items: result.map((e) => e.value)
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    resultTitle() {
        const keyword = this.state.keyword;
        if (keyword.length > 0) {
            return (<p>"{keyword}" 的搜索结果</p>)
        } else {
            return (<p/>)
        }
    }

    updateKeyword(keyword) {
        this.fetch(keyword)
    }

    render() {
        const {error, isLoaded, items} = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            );
        } else {
            const {keyword} = this.state;
            return (
                <div className="search-result col-12 col-sm-12 col-md-9 col-lg-8">
                    <h1 className="display-4">搜索</h1>
                    <SearchBox keyword={keyword} clickEvent={this.updateKeyword}/>
                    {this.resultTitle()}
                    <div className="bike-list">
                        <Bikes items={items}/>
                    </div>
                </div>
            );
        }
    }
}

export class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        let keyword = '';
        if (typeof(props.keyword) !== 'undefined') {
            keyword = props.keyword
        }

        this.state = {
            keyword: keyword,
            redirect: false
        };

      this.click = this.click.bind(this);
        this.keyPress = this.keyPress.bind(this)
    }

    click() {
        if (typeof(this.props.clickEvent) !== 'undefined') {
            this.props.clickEvent(this.state.keyword);
        }
        this.setState({redirect: true})
    }

    keyPress(e) {
        if (e.key === 'Enter') {
            this.click()
        }
    }

    redirectPath() {
        if (!this.state.redirect) {
            return
        }

        this.setState({redirect: false});
        const keyword = this.state.keyword;
        let params = "";

        if (typeof(keyword) !== 'undefined' && keyword !== "") {
            params = "?key=" + keyword;
        }

        return <Redirect to={"/search" + params} />
    }

    render() {
        const {keyword} = this.state;

        return (
            <div className="input-group mb-3">
              <input type="text" value={keyword} className="form-control" placeholder="搜索：Kawasaki, ktm 250 DUKE"
                     onChange={(e) => this.setState({keyword: e.target.value})} onKeyPress={this.keyPress}/>
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"
                            onClick={this.click}>搜索
                    </button>
                </div>
                {this.redirectPath()}
            </div>
        );
    }
}