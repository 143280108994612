import React from 'react';
import {SearchBox} from "./search";
import {currentTitle} from "./consts";

export default class Page404 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = currentTitle("404");
  }

  render() {
    const {location} = this.props;
    return (
        <div>
          <h1 className="display-4">OOPS! 404，页面未找到</h1>
          <h5></h5>
          <p>页面 <code>{location.pathname}</code> 未找到.</p>
          <p>你可以尝试浏览其他页面，或者搜索车</p>
          <SearchBox/>
          <p>可以输入关键词：</p>
          <ul style={{color: 'gray'}}>
            <li>品牌: <code> Kawasaki </code></li>
            <li>车型号: <code> 250 duke </code></li>
          </ul>
        </div>
    );
  }
}
