import React from 'react';
import {ApiDomain, currentTitle} from "./consts";
import {SearchBox} from "./search";
import {Link} from "react-router-dom";

export default class BrandList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    document.title = currentTitle();
    this.fetch();
  }

  fetch(next) {
    let params = "";
    if (typeof (next) !== 'undefined') {
      params = "?" + next;
    }

    fetch(ApiDomain + "/api/collections" + params)
        .then(res => res.json())
        .then(
            (result) => {
              const ids = this.state.items.map((it) => it.id);

              this.setState({
                isLoaded: true,
                items: this.state.items.concat(result.list.filter((it) => !ids.includes(it.id))),
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  render() {
    const {error, isLoaded, items} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      );
    } else {
      return (
          <div className="col-12 col-sm-12 col-md-9 col-lg-8">
            <SearchBox/>
            <h1 className="display-4">品牌列表</h1>
            <div className="row">
              {items.map(item => (
                  <Brand item={item}/>
              ))}
            </div>
          </div>
      );
    }
  }
}

export class Brand extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {item} = this.props;
    return (
        <div className="card col-lg-3 col-md-4 col-sm-6" key={item.id}>
          <div className="row">
            <Link to={`/brands/${item.name}`} className="card-body row" style={{textAlign: 'center'}}>
              <div style={{width: "100%"}}>
                <img src={item.iconUrl} style={{maxHeight: '120px', maxWidth: '100%', padding: '30px 0'}}/>
              </div>
              <h5 className="card-title text-muted col-12">{item.name}</h5>
            </Link>
          </div>
        </div>
    );
  }
}