import React from "react";
import {ApiDomain, currentTitle} from "./consts";

export default class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null,
      version: "正在查询...",
      url: "正在查询..."
    }
  }

  componentDidMount() {
    document.title = currentTitle('下载');
    this.fetch(this.state.keyword);
  }


  fetch() {
    fetch(ApiDomain + "/api/release")
        .then(res => res.json())
        .then(
            (result) => {
              this.setState({
                isLoaded: true,
                version: result.version,
                url: result.download_url
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
        )
  }

  render() {
    const {error, isLoaded} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">正在获取下载地址...</span>
          </div>
      );
    } else {
      const {version, url} = this.state;
      return (
          <div>
            <h1 className="display-4">下载</h1>
            <div className="download-card row">
              <div className="card">
                <div className="card-img-container align-content-center justify-content-center row">
                  <div className="logo col-lg-3 col-4">
                    <img className="card-img-top" src="/logo.png" alt="logo"/>
                  </div>
                </div>
                <div className="align-content-center justify-content-center row">
                  <h5 className="card-title">61摩托</h5>
                </div>
                <div className="card-body">
                  <div className={"scroll-horizontal"}>
                    <img src="https://moto-cdn.hashspace.cn/resource/Screenshot0.jpg"/>
                    <img src="https://moto-cdn.hashspace.cn/resource/Screenshot1.jpg"/>
                    <img src="https://moto-cdn.hashspace.cn/resource/Screenshot2.jpg"/>
                    <img src="https://moto-cdn.hashspace.cn/resource/Screenshot3.jpg"/>
                  </div>
                  <p className="card-text"> 当前最新版本是 v{version} </p>
                  <a href={url} className="btn btn-primary">安卓本地下载</a>
                  <div className="row">
                    <div style={{padding: 12}}>
                      <a href="https://apps.apple.com/cn/app/61%E6%91%A9%E6%89%98/id1474188514">
                        <img height="50" src="/appstore.svg"/>
                      </a>
                    </div>
                    <div className="google-play-btn">
                      <a href='https://play.google.com/store/apps/details?id=com.hashspace.moto&utm_source=61moto.com&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                          alt='下载应用，请到 Google Play'
                          src='https://play.google.com/intl/en_us/badges/images/generic/zh-cn_badge_web_generic.png'/></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    }
  }
}